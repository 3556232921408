import "./page_404.scss";

const Page404 = () => {
  return (
    <div className="page_404">
      404
      <br />
      Page not found
    </div>
  );
};

export default Page404;
