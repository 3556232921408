const paths = {
  dashboard: "/dashboard",
  login: "/login",
  register: "/register",
  customer_info: "/customer_info",
  service_info: "/service_info",
  usage_history: "/usage_history",
  register_service_package: "/register_service_package",
  payment: "/payment",
};

export default paths;
