import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authApi } from "../../api/authApi";
import { local } from "../../helper/localStorage";

interface ResponseState {
  AuthResponse: any;
  loading: boolean;
  error: string | null;
  accessToken: string | null;
  id: number | null;
}

const initialState: ResponseState = {
  AuthResponse: null,
  loading: false,
  error: null,
  accessToken: null,
  id: null,
};

export const changePassword = createAsyncThunk("auth/changePassword", async (data: any, { rejectWithValue }) => {
  try {
    const id = local.StorageGetSecure("id");
    const response = await authApi.changePassword(id, {
      old_password: data.old_password,
      new_password: data.new_password,
    });
    return response;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const logout = createAsyncThunk("auth/logout", async () => {
  try {
    const response = await authApi.logout();
    return response;
  } catch (error) {
    return error;
  }
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state: object, action) => ({ ...state, ...action.payload }),
    logout: (state) => ({ ...state, accessToken: null }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout.pending, (state) => {
        state.loading = true;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.loading = false;
        state.accessToken = null;
      })
      .addCase(logout.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
    builder
      .addCase(changePassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.loading = false;
        // state.AuthResponse = action.payload;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.loading = false;
        // state.AuthResponse = action.payload;
        // state.error = action.payload as string;
      });
  },
});

export default authSlice.reducer;
export const { setAuth } = authSlice.actions;
