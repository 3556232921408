import config from "../config";
import DefaultLayout from "../layouts/DefaultLayout";
import {
  CustomerInfo,
  Dashboard,
  Login,
  Payment,
  Register,
  RegisterServicePackage,
  ServiceInfo,
  UsageHistory,
} from "../page";
import { RouteType } from "../types/routes";

export const userRoutes: RouteType[] = [
  {
    path: config.dashboard,
    component: Dashboard,
    layout: DefaultLayout,
  },
  {
    path: config.customer_info,
    component: CustomerInfo,
    layout: DefaultLayout,
  },
  {
    path: config.service_info,
    component: ServiceInfo,
    layout: DefaultLayout,
  },
  {
    path: config.usage_history,
    component: UsageHistory,
    layout: DefaultLayout,
  },
  {
    path: config.register_service_package,
    component: RegisterServicePackage,
    layout: DefaultLayout,
  },
  {
    path: config.payment,
    component: Payment,
    layout: DefaultLayout,
  },
];

export const publicRoutes: RouteType[] = [
  {
    path: config.login,
    component: Login,
  },
  {
    path: config.register,
    component: Register,
  },
];
