import { Layout } from "antd";
import { Sidebar } from "../components/Sidebar";
import { useEffect, useState } from "react";
import { Content } from "antd/es/layout/layout";
import { MainHeader } from "../components/Header";
import { MainFooter } from "../components/Footer";

// const DefaultLayout = ({ children }: any) => {
//   return <Fragment>{children}</Fragment>;
// };
const DefaultLayout = ({ children }: any) => {
  const [collapsed, setCollapsed] = useState<boolean>(window.innerWidth <= 992);
  const handleResize = () => {
    if (window.innerWidth <= 992) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Layout className="default-layout">
      <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
      {/* <button onClick={() => setCollapsed(!collapsed)}>oo</button> */}
      <Layout className="main">
        {/* <Header className="header">
          <button className="header-button" onClick={() => setCollapsed(!collapsed)}>
            <i className="fa-light fa-bars-sort header-button-icon"></i>
          </button>
          <Dropdown></Dropdown>
        </Header> */}
        <MainHeader collapsed={collapsed} setCollapsed={setCollapsed} />
        <Content className={`content${collapsed ? " extend" : ""}`}>{children}</Content>
        <MainFooter />
      </Layout>
      <div className={`overlay${!collapsed ? " active" : ""}`} onClick={() => setCollapsed(!collapsed)}></div>
    </Layout>
  );
};

export default DefaultLayout;
