import axiosClient from "../axiosClient";

export const authApi = {
  async login(data: any) {
    const url = "customer/login";
    return axiosClient.post(url, data);
  },

  async logout() {
    const url = "customer/logout";
    return axiosClient.post(url);
  },

  async register(data: any) {
    const url = "customer/register";
    return axiosClient.post(url, data);
  },

  async changePassword(id: number, data: any) {
    const url = `customer/${id}/change-password`;
    return axiosClient.post(url, data);
  },

  async forgotPassword(data: any) {
    const url = "customer/forgot-password";
    return axiosClient.post(url, data);
  },

  async getOTP(data: any) {
    const url = "/send-email-otp";
    return axiosClient.post(url, data);
  },

  async checkOTP(data: any) {
    const url = "/check-email-otp";
    return axiosClient.post(url, data);
  },
};
