export const images = {
  image_logo: require("./image_logo.png"),
  image_jp_flag: require("./image_japan_flag.png"),
  image_vn_flag: require("./image_vietnam_flag.png"),
  image_front_sample: require("./image_front_sample.png"),
  image_end_sample: require("./image_end_sample.png"),
  image_face_sample: require("./image_face_sample.png"),
};

export default images;
