import {
  Button,
  Checkbox,
  CheckboxOptionType,
  Collapse,
  Empty,
  InputNumber,
  Radio,
  Select,
  Upload,
  UploadProps,
} from "antd";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { PaymentMethod, PaymentPrice, UserInfo } from "../../types/common";
import { getInfoAccount } from "../../redux/slices/userSlice";
import { useEffect, useState } from "react";
import overLoader from "../../components/overLoader";
import { getListPaymentMethod, getMinPayment, paymentRecord } from "../../redux/slices/paymentSlice";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { RcFile } from "antd/es/upload";
import showMessage from "../../components/openMessage";

export const Payment = () => {
  const [selectPrice, setSelectPrice] = useState<number>();
  const [defaultPrice, setDefaultPrice] = useState<number>();
  const [paymentType, setPaymentType] = useState<number>();
  const [confirmPrice, setConfirmPrice] = useState<boolean>(false);
  const [agreeTerms, setAgreeTerms] = useState<boolean>(false);
  const [listTransactionProof, setListTransactionProof] = useState<{ id: string; url: string }[]>([]);
  const [currentOption, setCurrentOption] = useState<number>();

  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.user.loading);
  const paymentLoading = useAppSelector((state) => state.payment.loading);
  const paymentMethod: PaymentMethod[] = useAppSelector((state) => state.payment.paymentMethod) as PaymentMethod[];
  const userInfo: UserInfo = useAppSelector((state) => state.user.userInfo) as UserInfo;
  const paymentPrice: PaymentPrice = useAppSelector((state) => state.payment.paymentPrice) as PaymentPrice;

  useEffect(() => {
    dispatch(getListPaymentMethod());
    dispatch(getInfoAccount());
  }, [dispatch]);
  useEffect(() => {
    if (userInfo?.list_sim?.length > 0) {
      dispatch(
        getMinPayment({
          phone: userInfo?.list_sim[0],
          month: dayjs().format("YYYYMM"),
        }),
      );
    }
  }, [dispatch, userInfo?.list_sim]);
  useEffect(() => {
    setDefaultPrice(paymentPrice?.minPayment);
  }, [paymentPrice?.minPayment]);

  const handleSimChange = (value: number) => {
    dispatch(
      getMinPayment({
        phone: userInfo?.list_sim[value],
        month: dayjs().format("YYYYMM"),
      }),
    );
    setDefaultPrice(paymentPrice?.minPayment);
    setCurrentOption(value);
  };

  const optionsSim: CheckboxOptionType[] = userInfo?.list_sim?.map((item, index) => {
    return { label: item, value: index };
  });

  const isShippingEmpty = userInfo?.shipping?.length === 0;
  const isPaymentEmpty = userInfo?.shipping?.[0]?.payment_method === "" && userInfo?.shipping?.[0]?.payment_code === "";
  const optionsPaymentMethod: CheckboxOptionType[] = paymentMethod?.map((item: PaymentMethod) => {
    return {
      value: item.id,
      label: <span className="table-checkout-method-select-text">{item?.name}</span>,
      disabled: item?.id === 0 ? isShippingEmpty || isPaymentEmpty : item?.id === 2 ? true : false,
    };
  });
  //   console.log(optionsSim);
  //   console.log(paymentPrice);

  const listSuggestMoney = [1000, 3000, 5000, 10000];
  const smartpitFee = 130;
  let totalPayment = 0;
  if (confirmPrice && paymentType !== undefined) {
    if (paymentType === 0) {
      totalPayment = selectPrice
        ? selectPrice - paymentPrice?.umobilePoint + smartpitFee
        : paymentPrice?.minPayment - paymentPrice?.umobilePoint + smartpitFee;
    } else {
      totalPayment = selectPrice
        ? selectPrice - paymentPrice?.umobilePoint
        : paymentPrice?.minPayment - paymentPrice?.umobilePoint;
    }
  }

  const getImageBase64 = (file: RcFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  const propsUpload: UploadProps = {
    listType: "picture",
    maxCount: 2,
    beforeUpload: async (file: RcFile) => {
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png";
      if (!isJpgOrPng) {
        showMessage.error("Vui lòng chọn file ảnh có định dạng jpg hoặc png");
        return Upload.LIST_IGNORE;
      }
      const imageBase64 = await getImageBase64(file);
      listTransactionProof?.length < 2 &&
        setListTransactionProof([...listTransactionProof, { id: file.uid, url: imageBase64 as string }]);
      return false;
    },
    onRemove: (file) => {
      const index = listTransactionProof.findIndex((item) => item.id === file.uid);
      if (index !== -1) {
        const newFileList = [...listTransactionProof];
        newFileList.splice(index, 1);
        setListTransactionProof(newFileList);
      }
    },
  };
  const handleSubmitPayment = async () => {
    let data: any = {};
    data = {
      payment_method: paymentType,
      money: totalPayment,
      type: 0,
    };
    if (paymentType === 1) {
      data = {
        ...data,
        image1_base64: listTransactionProof[0].url,
        image2_base64: listTransactionProof[1].url,
      };
    }
    if (totalPayment > 0) {
      try {
        const resData: any = await dispatch(paymentRecord(data));
        // console.log(resData);
        if (resData?.payload?.code === 201) {
          showMessage.success(resData?.payload?.msg);
          setPaymentType(undefined);
          setAgreeTerms(false);
          setListTransactionProof([]);
          setConfirmPrice(false);
          setSelectPrice(undefined);
          setDefaultPrice(paymentPrice?.minPayment);
          setCurrentOption(0);
          dispatch(getMinPayment({ phone: userInfo?.list_sim[0], month: dayjs().format("YYYYMM") }));
        } else {
          showMessage.error(resData?.payload?.msg);
        }
      } catch (error: any) {
        showMessage.error(error?.payload?.msg);
      }
    }
  };

  return (
    <div className="wrapper payment">
      {loading && overLoader()}
      {paymentLoading && overLoader()}
      <div className="main">
        <div className="main-content">
          <div className="payment-list">
            {userInfo?.list_sim?.length === 0 ? (
              <Empty description="Bạn chưa đăng kí số điện thoại nào" />
            ) : (
              <>
                <div className="payment-item">
                  <div className="payment-info">
                    <div className="payment-info-item">
                      <span className="payment-info-item-title">電話番号</span>
                      <span className="payment-info-item-content">
                        <Select
                          options={optionsSim}
                          defaultValue={0}
                          value={currentOption}
                          defaultActiveFirstOption
                          className="payment-info-item-select"
                          onChange={handleSimChange}
                        />
                      </span>
                    </div>
                    <div className="payment-info-item">
                      <span className="payment-info-item-title">{dayjs().add(1, "month").month()}月分利用料金</span>
                      <span className="payment-info-item-content">{paymentPrice?.minPayment} 円</span>
                    </div>
                    <div className="payment-info-item">
                      <span className="payment-info-item-title">お支払期限</span>
                      <span className="payment-info-item-content">
                        {dayjs().year()}年{dayjs().add(2, "month").month()}月25日
                      </span>
                    </div>
                    <div className="payment-info-item">
                      <span className="payment-info-item-title">Umobile Point</span>
                      <span className="payment-info-item-content">{paymentPrice?.umobilePoint}</span>
                    </div>
                    <div className="payment-info-item">
                      <span className="payment-info-item-title">Smartpit番号</span>
                      <span className="payment-info-item-content">211 000 333 4455</span>
                    </div>
                  </div>
                  <div className="payment-button-wrapper">
                    <Button className="payment-button">利用料金.明細確認</Button>
                  </div>
                </div>
                <div className="payment-item no-style">
                  <div className="payment-table">
                    <div className="payment-table-checkout">
                      <h1 className="table-checkout-title table-checkout-item">入金チャージ</h1>
                      <div className="table-checkout-money table-checkout-item">
                        <h2 className="table-checkout-money-title">最低支払額</h2>
                        <div className="table-checkout-money-fixed">
                          <span className="table-checkout-money-fixed-content">
                            {/* {selectPrice ? selectPrice : paymentPrice?.minPayment - paymentPrice?.umobilePoint} 円 */}
                            <InputNumber
                              addonAfter="円"
                              value={defaultPrice}
                              defaultValue={paymentPrice?.minPayment}
                              variant="borderless"
                              className="table-checkout-money-fixed-content-input"
                            />
                          </span>
                          <Button
                            type="primary"
                            className="table-checkout-money-fixed-button"
                            onClick={() => {
                              setConfirmPrice(true);
                              setSelectPrice(defaultPrice);
                            }}
                          >
                            金额指定
                          </Button>
                          <Button
                            className="table-checkout-money-fixed-button"
                            onClick={() => setDefaultPrice(paymentPrice?.minPayment)}
                            disabled={defaultPrice === paymentPrice?.minPayment}
                          >
                            リセット
                          </Button>
                        </div>
                        <div className="table-checkout-money-suggest">
                          {/* <span className="table-checkout-money-suggest-item">1000円</span>
                      <span className="table-checkout-money-suggest-item">3000円</span>
                      <span className="table-checkout-money-suggest-item">5000円</span>
                      <span className="table-checkout-money-suggest-item">10000円</span> */}
                          {listSuggestMoney.map((item) => (
                            <div
                              key={item}
                              className={`table-checkout-money-suggest-item${
                                item < paymentPrice?.minPayment ? " disabled" : ""
                              }`}
                              onClick={() => {
                                item > paymentPrice?.minPayment && setDefaultPrice(item);
                                setConfirmPrice(false);
                              }}
                            >
                              <span className="table-checkout-money-suggest-item-number">{item}</span>
                              <span className="table-checkout-money-suggest-item-unit">円</span>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="table-checkout-method table-checkout-item">
                        <h2 className="table-checkout-method-title">お支払方法</h2>
                        <Radio.Group
                          options={optionsPaymentMethod}
                          value={paymentType}
                          onChange={(e) => setPaymentType(e.target.value)}
                          className="table-checkout-method-select"
                        />
                      </div>
                    </div>
                    <div
                      className={`payment-table-confirm${
                        !confirmPrice || paymentType === undefined ? " disabled" : ""
                      }`}
                    >
                      <h1 className="payment-table-confirm-title">入金内容</h1>
                      <div className="payment-table-confirm-detail">
                        <div className="payment-table-confirm-detail-item">
                          <span className="payment-table-confirm-detail-item-title">金额</span>
                          <span className="payment-table-confirm-detail-item-content">{selectPrice} 円</span>
                        </div>
                        <div className="payment-table-confirm-detail-item">
                          <span className="payment-table-confirm-detail-item-title">割引</span>
                          <span className="payment-table-confirm-detail-item-content">
                            -{paymentPrice?.umobilePoint} 円
                          </span>
                        </div>
                        {paymentType === 0 && (
                          <div className="payment-table-confirm-detail-item">
                            <span className="payment-table-confirm-detail-item-title">Smartpit 支払手数料</span>
                            <span className="payment-table-confirm-detail-item-content">{smartpitFee} 円</span>
                          </div>
                        )}
                      </div>
                      <div className="payment-table-confirm-total">
                        <span className="payment-table-confirm-total-title">合計金额</span>
                        <span className="payment-table-confirm-total-content">{totalPayment} 円</span>
                      </div>
                      <div className="payment-table-confirm-terms">
                        <Checkbox
                          checked={agreeTerms}
                          onChange={(e) => setAgreeTerms(e.target.checked)}
                          className="payment-table-confirm-terms-checkbox"
                        >
                          Tôi đồng ý với điều khoản mua hàng và thanh toán của Umobile
                        </Checkbox>
                      </div>
                      {paymentType === 0 ? (
                        <>
                          <div className="payment-table-confirm-info">
                            <span className="payment-table-confirm-info-item">• 手数料は130円/回</span>
                            <span className="payment-table-confirm-info-item">
                              • Lawson, Family, MiniStopでお支払い可能
                            </span>
                            <Collapse
                              items={[
                                {
                                  key: 1,
                                  label: "お支払いガイドはこちら",
                                  children: (
                                    <>
                                      <Link
                                        to="https://www.smartpit.jp/consumer/payment/loppi.html"
                                        target="_blank"
                                        className="payment-table-confirm-info-item-link"
                                      >
                                        • Lawson
                                      </Link>
                                      <Link
                                        to="https://www.smartpit.jp/consumer/payment/familymart.html"
                                        target="_blank"
                                        className="payment-table-confirm-info-item-link"
                                      >
                                        • Family、MiniStop
                                      </Link>
                                    </>
                                  ),
                                },
                              ]}
                              ghost
                              className="payment-table-confirm-info-item"
                            />
                          </div>
                        </>
                      ) : paymentType === 1 ? (
                        <div className="payment-table-confirm-info">
                          <span className="payment-table-confirm-info-item">ゆうちょ銀行: 11330-22962241</span>
                          <span className="payment-table-confirm-info-item">カ)ユーティリティーハウス</span>
                          <span className="payment-table-confirm-info-item">店名: 一三八</span>
                          <span className="payment-table-confirm-info-item">店番: 138</span>
                          <span className="payment-table-confirm-info-item note">
                            振込売了したら明細を送信してください
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}

                      <div className="payment-table-confirm-button">
                        {/* {(paymentType === 0 || paymentType === 1) && (
                          <Button
                            type="primary"
                            icon={paymentType === 1 && <i className="fa-light fa-cloud-arrow-up"></i>}
                            iconPosition="start"
                            disabled={!confirmPrice || !agreeTerms}
                            className="payment-table-confirm-btn"
                          >
                            {paymentType === 0 ? "Xác nhận" : paymentType === 1 ? "Tải lên minh chứng" : ""}
                          </Button>
                        )} */}
                        {paymentType === 0 && (
                          <Button
                            type="primary"
                            disabled={!confirmPrice || !agreeTerms}
                            className="payment-table-confirm-btn"
                            onClick={handleSubmitPayment}
                          >
                            Xác nhận
                          </Button>
                        )}
                        {paymentType === 1 && (
                          <Upload {...propsUpload} className="payment-table-confirm-upload">
                            <Button
                              icon={<i className="fa-light fa-cloud-arrow-up"></i>}
                              iconPosition="start"
                              className="payment-table-confirm-upload-btn"
                            >
                              Tải lên minh chứng
                            </Button>
                          </Upload>
                        )}
                        {paymentType === 1 && (
                          <Button
                            type="primary"
                            disabled={!confirmPrice || !agreeTerms || listTransactionProof.length < 2}
                            className="payment-table-confirm-btn"
                            onClick={handleSubmitPayment}
                          >
                            Xác nhận
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
