import { message } from "antd";
const showMessage = {
  success: (content: string) => {
    message.open({
      type: "success",
      content,
    });
  },
  error: (content: string) => {
    message.open({
      type: "error",
      content,
    });
  },
};
export default showMessage;
