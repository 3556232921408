import axios from "axios";
import { local } from "../helper/localStorage";
import showMessage from "../components/openMessage";

const languageLocal = local.StorageGetSecure("language");

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "content-type": "application/json",
    lang: languageLocal || "ja",
  },
});

axiosClient.interceptors.request.use(
  (config) => {
    const newConfig = config;
    const token = JSON.parse(local.StorageGetSecure("access_token"));
    const languageLocal = local.StorageGetSecure("language");
    if (token && token !== "undefined" && token !== "null") {
      newConfig.headers.Authorization = `Bearer ${token}`;
    }
    newConfig.headers.lang = languageLocal || "ja";
    return newConfig;
  },
  (error) => Promise.reject(error),
);

axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data && response.data.data && response.data.data.rows) {
      return response.data.data.rows;
    }
    if (response && response.data) {
      return response.data;
    }
    return response;
  },
  async (errors) => {
    if (errors?.response?.status === 401) {
      localStorage.clear();
      window.location.href = "/login";
    }
    if (errors?.response?.status === 500) {
      showMessage.error(errors?.response?.data?.msg);
    }
    return Promise.reject(errors);
  },
);
export default axiosClient;
