import { Button, Checkbox, Form, Input } from "antd";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DataRegister } from "../../../types/auth";
import { authApi } from "../../../api/authApi";
import overLoader from "../../../components/overLoader";
// import { AuthProps } from "../../../types/common";
import { useForm } from "antd/es/form/Form";
import { useTranslation } from "react-i18next";
import images from "../../../assets/images";
import showMessage from "../../../components/openMessage";
import { local } from "../../../helper/localStorage";

export const Register = () => {
  const [statusLoader, setStatusLoader] = useState<boolean>(false);
  const [statusEmail, setStatusEmail] = useState<boolean>(false);
  //   const [, showPopup] = message.useMessage();
  const [form] = useForm();
  const navigate = useNavigate();
  const [language, setLanguage] = useState<string>("ja");
  const { t, i18n } = useTranslation(["auth", "common"]);
  const languageLocal = local.StorageGetSecure("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "ja");
    setLanguage(languageLocal || language);
  }, [languageLocal, language, i18n]);

  const handleChangeLanguage = (lang: string) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
    local.StorageSetSecure("language", lang);
  };

  const handleRegister = async (data: DataRegister) => {
    setStatusLoader(true);
    try {
      const resData: any = await authApi.register({
        name: data.name,
        email: data.email,
        password: data.password,
        password_confirmation: data.password_confirmation,
      });
      if (resData.code === 201) {
        setStatusLoader(false);
        form.resetFields();
        showMessage.success(resData?.msg);
        setTimeout(() => {
          navigate("/login");
        }, 100);
      }
    } catch (err: any) {
      setStatusLoader(false);
      showMessage.error(err?.response?.data?.msg);
    }
  };
  const handleUpperCase = (e: any) => {
    e.target.value = e.target.value.toUpperCase();
  };
  const handleGetOTP = async () => {
    const email = form.getFieldValue("email");
    if (!email) {
      form.setFields([{ name: "email", errors: ["Vui lòng nhập email"] }]);
    } else {
      try {
        setStatusLoader(true);
        const resData: any = await authApi.getOTP({ email });
        if (resData.status === false) {
          setStatusLoader(false);
          showMessage.success(resData?.msg);
        } else {
          setStatusLoader(false);
          showMessage.error(resData?.msg);
          setStatusEmail(true);
        }
      } catch (err: any) {
        setStatusLoader(false);
        showMessage.error(err?.response?.data?.msg);
      }
    }
  };
  const handleCheckOTP = async () => {
    const email = form.getFieldValue("email");
    const otp = form.getFieldValue("otp");
    if (otp.length === 6) {
      try {
        setStatusLoader(true);
        const resData: any = await authApi.checkOTP({ email, otp });
        if (resData.status === true) {
          setStatusLoader(false);
          showMessage.success(resData?.msg);
          setStatusEmail(true);
        } else {
          setStatusLoader(false);
          showMessage.error(resData?.msg);
          setStatusEmail(false);
        }
      } catch (err: any) {
        setStatusLoader(false);
        showMessage.error(err?.response?.data?.msg);
      }
    }
  };

  return (
    <div className="wrapper register">
      <button className="register-language" onClick={() => handleChangeLanguage(language === "ja" ? "vi" : "ja")}>
        <div className="register-language-icon">
          <img src={language === "ja" ? images.image_vn_flag : images.image_jp_flag || images.image_vn_flag} alt="" />
        </div>
      </button>
      <div className="register-welcome">
        <div className="register-welcome-header">
          {/* <div className="register-welcome-header-logo"> */}
          <img src={images.image_logo} alt="" />
          {/* </div>
          <span className="register-welcome-header-text">Umobile</span> */}
        </div>
        <h1 className="register-welcome-title">{t("chao_mung_dang_nhap")}</h1>
        <p className="register-welcome-description">{t("gioi_thieu_umobile")}</p>
      </div>
      <div className="register-form container">
        <h1 className="register-form-title">{t("tao_tai_khoan")}</h1>
        <div className="register-form-login">
          <span>{t("da_co_tai_khoan")}</span>
          <Link to="/login" className="register-form-login-link">
            {t("dang_nhap")}
          </Link>
        </div>
        <Form
          className="register-form-main"
          form={form}
          colon={false}
          layout="vertical"
          onFinish={handleRegister}
          autoComplete="off"
          //   initialValues={{ terms: false }}
        >
          {statusLoader && overLoader()}
          {/* {showPopup} */}
          <Form.Item
            label={t("ho_ten")}
            name="name"
            className="register-form-item"
            rules={[
              { required: true, message: `${t("common:vui_long_nhap")} ${t("ho_ten")}` },
              { pattern: /^[A-Z ]+$/, message: t("yeu_cau_ho_ten") },
            ]}
          >
            <Input
              type="name"
              className="register-form-input"
              placeholder={t("ho_ten")}
              tabIndex={1}
              onInput={handleUpperCase}
            />
          </Form.Item>
          <Form.Item
            label={
              <div className="register-form-label">
                <span className="register-form-label-text">{t("email")}</span>
                <button type="button" className="register-form-label-otp" onClick={handleGetOTP}>
                  {t("lay_ma_otp")}
                </button>
              </div>
            }
            name="email"
            className="register-form-item"
            rules={[
              { required: true, message: `${t("common:vui_long_nhap")} ${t("email")}` },
              { type: "email", message: t("yeu_cau_email") },
            ]}
          >
            <Input type="email" className="register-form-input" placeholder={t("email")} tabIndex={2} />
          </Form.Item>
          <Form.Item
            label={t("ma_otp")}
            name="otp"
            className="register-form-item"
            rules={[
              !statusEmail ? { required: true, message: `${t("common:vui_long_nhap")} ${t("ma_otp")}` } : {},
              { pattern: /^[0-9]+$/, message: t("yeu_cau_otp") },
            ]}
          >
            <Input
              type="text"
              inputMode="numeric"
              maxLength={6}
              className="register-form-input"
              placeholder={t("ma_otp")}
              tabIndex={3}
              onChange={handleCheckOTP}
            />
          </Form.Item>
          {/* <Form.Item className="register-form-item"> */}
          <Form.Item
            label={t("mat_khau")}
            name="password"
            className="register-form-item password"
            rules={[
              { required: true, message: `${t("common:vui_long_nhap")} ${t("mat_khau")}` },
              { min: 8, message: t("yeu_cau_mat_khau") },
            ]}
          >
            <Input.Password type="password" className="register-form-input" placeholder={t("mat_khau")} tabIndex={4} />
          </Form.Item>
          {/* <span className="register-form-item-require">8文字以上のパスワード</span> */}
          {/* </Form.Item> */}
          <Form.Item
            label={t("xac_nhan_mat_khau")}
            name="password_confirmation"
            dependencies={["password"]}
            className="register-form-item"
            rules={[
              {
                required: true,
                message: `${t("common:vui_long_nhap")} ${t("xac_nhan_mat_khau")}`,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t("yeu_cau_mat_khau_xac_nhan")));
                },
              }),
            ]}
          >
            <Input.Password
              type="password"
              className="register-form-input"
              placeholder={t("xac_nhan_mat_khau")}
              tabIndex={5}
              autoComplete="new-password"
            />
          </Form.Item>
          <Form.Item
            name="terms"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value ? Promise.resolve() : Promise.reject(new Error(t("yeu_cau_dieu_khoan"))),
              },
            ]}
            className="register-form-item"
          >
            <Checkbox className="register-form-item-checkbox">
              {t("toi_dong_y_voi")}{" "}
              <Link to="terms" className="register-form-item-link">
                {t("dieu_khoan_su_dung")}
              </Link>
            </Checkbox>
          </Form.Item>
          <Button type="primary" htmlType="submit" className="register-submit-button">
            {t("dang_ki")}
          </Button>
        </Form>
      </div>
    </div>
  );
};
