import axiosClient from "../axiosClient";
import axiosAddress from "../../helper/getAddress";

export const userApi = {
  async getInfoAccount(id: number) {
    const url = `customer/${id}`;
    return axiosClient.get(url);
  },

  async verifyInfoAccount(id: number, data: any) {
    const url = `customer/${id}/identity-verification`;
    return axiosClient.post(url, data);
  },

  async updateInfoAccount(id: number, data: any) {
    const url = `customer/${id}`;
    return axiosClient.post(url, data);
  },

  async getQuotaInfo(id: number, phone: string) {
    const url = `customer/${id}/esim-data/${phone}`;
    return axiosClient.get(url);
  },

  async getAddressByPostCode(postCode: string) {
    const zipCode1 = postCode.slice(0, 3);
    const zipCode2 = postCode.slice(3, 7);
    const url = `/${zipCode1}/${zipCode2}.json`;
    return axiosAddress.get(url);
  },

  async getContractInfo() {
    const url = "/customer/contracts";
    return axiosClient.get(url);
  },

  async getUsageHistory(id: number, phone: string, month: string) {
    const url = `/customer/${id}/talk-sms-detail/${phone}/${month}`;
    return axiosClient.get(url);
  },

  async getAllPackage() {
    const url = "/packages";
    return axiosClient.get(url);
  },

  async registerServicePackage(id: number, data: any) {
    const url = `/customer/${id}/request`;
    return axiosClient.post(url, data);
  },

  async showPriceHoudai() {
    const url = "/packages/show-price-houdai";
    return axiosClient.get(url);
  },
};
