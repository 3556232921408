import CryptoJS from "crypto-js";

export const session = {
  StorageSetSecure: (key: string, value: any) => {
    const data = CryptoJS.AES.encrypt(JSON.stringify(value), process.env.REACT_APP_HASH_KEY!).toString();
    sessionStorage.setItem(`${key}_um`, data);
  },
  StorageGetSecure: (key: string) => {
    const itemStr: string | null = sessionStorage.getItem(`${key}_um`);
    if (!itemStr) {
      return null;
    }
    const tmpData: CryptoJS.lib.WordArray = CryptoJS.AES.decrypt(
      itemStr === null || itemStr === undefined ? "" : itemStr,
      process.env.REACT_APP_HASH_KEY!,
    );
    const item = JSON?.parse(tmpData?.toString(CryptoJS.enc.Utf8));
    return item;
  },
  StorageRemoveSecure: (key: string) => {
    sessionStorage.removeItem(`${key}_um`);
  },
  StorageClear: () => {
    sessionStorage.clear();
  },
};
