import { Fragment, Suspense } from "react";
import "./assets/icons/css/all.css";
import "./assets/scss/style.scss";
import { Spin } from "antd";
import { Navigate, Route, Routes } from "react-router-dom";
import { publicRoutes, userRoutes } from "./routes";
import ProtectedRoutes from "./routes/protectedRoutes";
import Page404 from "./page/404";

function App() {
  const publicRoute = publicRoutes.map((route) => {
    const Page = route.component;
    let Layout: any = Fragment;

    if (route.layout) {
      Layout = route.layout;
    } else if (route.layout === null) {
      Layout = Fragment;
    }

    return (
      <Route
        key={route.path}
        path={route.path}
        element={
          <Layout>
            <Page />
          </Layout>
        }
      />
    );
  });

  const userRoute = userRoutes.map((route) => {
    const Page = route.component;
    let Layout: any = Fragment;

    if (route.layout) {
      Layout = route.layout;
    } else if (route.layout === null) {
      Layout = Fragment;
    }
    return (
      <Route
        key={route.path}
        path={route.path}
        element={
          <Layout>
            <Page />
          </Layout>
        }
      />
    );
  });

  return (
    <div className="App">
      <Suspense fallback={<Spin />}>
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard" />} />
          {publicRoute}
          <Route element={<ProtectedRoutes />}>{[...userRoute]}</Route>
          <Route path="*" element={<Page404 />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
