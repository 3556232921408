import { Link, useNavigate } from "react-router-dom";
// import image_logo from "../../assets/images/image_logo.png";
import { LayoutProps, UserInfo } from "../../types/common";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { useEffect, useState } from "react";
import i18n from "../../i18n";
import images from "../../assets/images";
import { Header } from "antd/es/layout/layout";
import { Button, Dropdown, Form, Input, MenuProps, Modal } from "antd";
import paths from "../../config";
import { changePassword, logout } from "../../redux/slices/authSlice";
import showMessage from "../openMessage";
import { useTranslation } from "react-i18next";
import { local } from "../../helper/localStorage";
import overLoader from "../overLoader";
import { session } from "../../helper/sessionStorage";

export const MainHeader = ({ collapsed, setCollapsed }: LayoutProps) => {
  const [statusLoader, setStatusLoader] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [openChangePassword, setOpenChangePassword] = useState<boolean>(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userInfo: UserInfo = useAppSelector((state: any) => state.user.userInfo);
  const [language, setLanguage] = useState<string>("ja");
  const languageLocal: any = local.StorageGetSecure("language");
  const { t } = useTranslation(["common", "auth"]);

  const handleLanguage = (lang: string) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
    local.StorageSetSecure("language", lang);
  };

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "ja");
    setLanguage(languageLocal || language);
  }, [languageLocal, language]);

  const handleLogout = async () => {
    setStatusLoader(true);
    setVisible(false);
    try {
      const resData: any = await dispatch(logout());
      if (resData.payload.code === 200) {
        setStatusLoader(false);
        showMessage.success(resData?.payload?.msg);
        local.StorageClear();
        session.StorageClear();
        // localStorage.removeItem("access_token_um");
        // localStorage.removeItem("id");
        // if (localStorage.getItem("language")) {
        // localStorage.removeItem("language");
        // }
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      } else {
        setStatusLoader(false);
        showMessage.error(resData?.payload?.msg);
      }
    } catch (error: any) {
      setStatusLoader(false);
      showMessage.error(error?.payload?.msg);
    }
  };
  const handleChangePassword = async (data: any) => {
    setStatusLoader(true);
    try {
      const resData: any = await dispatch(changePassword(data));
      if (resData.payload.code === 200) {
        setStatusLoader(false);
        showMessage.success(resData?.payload?.msg);
        setTimeout(() => {
          setOpenChangePassword(false);
          form.resetFields();
        }, 1000);
      } else {
        setStatusLoader(false);
        showMessage.error(resData?.payload?.msg);
      }
    } catch (error: any) {
      setStatusLoader(false);
      showMessage.error(error?.payload?.msg);
    }
  };

  const items: MenuProps["items"] = [
    {
      key: "profile",
      label: (
        <Link to={paths.customer_info} className="dropdown-item-link">
          {t("common:thong_tin_khach_hang")}
        </Link>
      ),
      icon: (
        <span className="dropdown-item-icon">
          <i className="fa-thin fa-circle-user"></i>
        </span>
      ),
    },
    {
      key: "language",
      label: <span className="dropdown-item-link">{language === "ja" ? "Tiếng Việt" : "日本語"}</span>,
      icon: (
        <div className="dropdown-item-flag">
          <img src={language === "ja" ? images.image_vn_flag : images.image_jp_flag || images.image_jp_flag} alt="" />
        </div>
      ),
      onClick: () => handleLanguage(language === "ja" ? "vi" : "ja"),
    },
    {
      key: "change_password",
      label: <span className="dropdown-item-link">{t("auth:doi_mat_khau")}</span>,
      icon: (
        <span className="dropdown-item-icon">
          <i className="fa-thin fa-lock"></i>
        </span>
      ),
      onClick: () => setOpenChangePassword(true),
    },
    {
      type: "divider",
    },
    {
      key: "logout",
      label: (
        // <Popover
        //   placement="left"
        //   //   trigger="click"
        //   //   arrow={false}
        //   title="Bạn có chắc chắn muốn đăng xuất?"
        //   content={
        //     <Button block type="primary" danger onClick={handleLogout}>
        //       Ok
        //     </Button>
        //   }
        // >
        <div className="dropdown-item" onClick={() => setVisible(true)}>
          <span className="dropdown-item-icon">
            <i className="fa-thin fa-arrow-right-from-bracket"></i>
          </span>
          <span className="dropdown-item-link">{t("auth:dang_xuat")}</span>
        </div>
        // </Popover>
      ),
      //   icon: (
      //     <span className="dropdown-item-icon">
      //       <i className="fa-thin fa-arrow-right-from-bracket"></i>
      //     </span>
      //   ),
      //   onClick: handleLogout,
      danger: true,
    },
  ];
  return (
    <Header className={`header${collapsed ? " extend" : ""}`}>
      {statusLoader && overLoader()}
      <Modal
        centered
        title={t("auth:xac_nhan_dang_xuat")}
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        className="logout-modal"
      >
        {/* <h2>{t("auth:ban_co_chac_chan_muon_dang_xuat")}</h2> */}
        <Button onClick={() => setVisible(false)}>{t("common:huy")}</Button>
        <Button type="primary" danger onClick={handleLogout}>
          {t("common:dong_y")}
        </Button>
      </Modal>
      <Modal
        title={t("auth:doi_mat_khau")}
        open={openChangePassword}
        onCancel={() => {
          setOpenChangePassword(!openChangePassword);
          form.resetFields();
        }}
        footer={null}
        centered
        className="change-password-modal"
      >
        <Form
          form={form}
          labelAlign="left"
          labelCol={{ span: 9 }}
          wrapperCol={{ span: 15 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          onFinish={handleChangePassword}
          className="change-password-form"
        >
          <Form.Item
            label={t("auth:mat_khau_cu")}
            className="change-password-form-item"
            name="old_password"
            rules={[
              {
                required: true,
                message: "Vui lòng không để trống",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label={t("auth:mat_khau_moi")}
            className="change-password-form-item"
            name="new_password"
            rules={[
              {
                required: true,
                message: "Vui lòng không để trống",
              },
              {
                min: 8,
                message: "Mật khẩu phải lớn hơn 8 ký tự",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label={t("auth:xac_nhan_mat_khau")}
            className="change-password-form-item"
            name="confirm_password"
            rules={[
              {
                required: true,
                message: "Vui lòng không để trống",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("new_password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Mật khẩu không khớp!"));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item className="change-password-form-item change-password-form-button" wrapperCol={{ span: 24 }}>
            <Button
              type="default"
              className="change-password-form-button-cancel"
              onClick={() => setOpenChangePassword(false)}
            >
              {t("common:huy")}
            </Button>
            <Button type="primary" htmlType="submit" className="change-password-form-button-submit">
              {t("common:xac_nhan")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <button className="header-button" onClick={() => setCollapsed && setCollapsed(!collapsed)}>
        <i className="fa-light fa-bars-sort header-button-icon"></i>
      </button>
      <Dropdown
        menu={{
          items,
        }}
        trigger={["click"]}
        className="header-dropdown"
      >
        {userInfo?.avatar ? (
          <div className="user-avt">
            <img src={userInfo.avatar} alt="avatar" />
          </div>
        ) : (
          <span className="user-avt">
            <i className="fa-solid fa-circle-user icon-user"></i>
          </span>
        )}
      </Dropdown>
    </Header>
  );
};
