import { Button, Form, Input } from "antd";
import { useState } from "react";
import overLoader from "../../../components/overLoader";
import { authApi } from "../../../api/authApi";
import { AuthProps } from "../../../types/common";
import { useTranslation } from "react-i18next";
import showMessage from "../../../components/openMessage";

export const ForgotPassword = ({ handleShowModal }: AuthProps) => {
  const [statusLoader, setStatusLoader] = useState<boolean>(false);
  //   const [, showPopup] = notification.useNotification();
  const { t } = useTranslation(["auth", "common"]);

  const handleForgotPassword = async (data: any) => {
    setStatusLoader(true);
    try {
      const resData: any = await authApi.forgotPassword(data);
      if (resData.status === true) {
        showMessage.success(resData?.msg);
        setStatusLoader(false);
        setTimeout(() => {
          handleShowModal();
        }, 1000);
      } else {
        setStatusLoader(false);
        showMessage.error(resData?.msg);
      }
    } catch (err: any) {
      setStatusLoader(false);
      showMessage.error(err?.response?.data?.msg);
    }
  };

  return (
    <Form
      className="forgot-password-form"
      onFinish={handleForgotPassword}
      labelAlign="left"
      labelCol={{ span: 5 }}
      wrapperCol={{ span: 19 }}
      initialValues={{ remember: true }}
      autoComplete="off"
    >
      {statusLoader && overLoader()}
      {/* {showPopup} */}
      <Form.Item
        label={t("email")}
        name="email"
        rules={[
          {
            required: true,
            message: `${t("common:vui_long_nhap")} ${t("email")}`,
          },
          {
            type: "email",
            message: t("yeu_cau_email"),
          },
        ]}
        className="forgot-password-form-item"
      >
        <Input className="form-item-input" />
      </Form.Item>
      <Button type="primary" htmlType="submit" className="btn-submit">
        {t("common:gui")}
      </Button>
    </Form>
  );
};
