import axios from "axios";

const axiosAddress = axios.create({
  baseURL: process.env.REACT_APP_ADDRESS_API,
});

axiosAddress.interceptors.request.use(
  (response) => {
    if (response && response.data && response.data.data && response.data.data.rows) {
      return response.data.data.rows;
    }
    if (response && response.data) {
      return response.data;
    }
    return response;
  },
  async (errors) => {
    if (errors.response?.status === 401) {
    }
    return Promise.reject(errors);
  },
);

export default axiosAddress;
