import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { AllPackage, PackageHoudai, PackageInfo, UserInfo } from "../../types/common";
import { local } from "../../helper/localStorage";
import { session } from "../../helper/sessionStorage";
import { useTranslation } from "react-i18next";
import { getAllPackage, getInfoAccount, registerServicePackage, showPriceHoudai } from "../../redux/slices/userSlice";
import overLoader from "../../components/overLoader";
import showMessage from "../../components/openMessage";
import {
  Button,
  Collapse,
  CollapseProps,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  StepProps,
  Steps,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import { formatPrice } from "../../utils/function";
import { Link } from "react-router-dom";

export const RegisterServicePackage = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [errorState, setErrorState] = useState<boolean[]>([true, true, true, true, true]);
  const [packagePrice, setPackagePrice] = useState<number>(0);
  const [packagePriceThisMonth, setPackagePriceThisMonth] = useState<number>(0);
  const [houdaiPrice, setHoudaiPrice] = useState<number>(0);
  const [houdaiPriceThisMonth, setHoudaiPriceThisMonth] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [form] = Form.useForm();

  const dispatch = useAppDispatch();
  const loading: boolean = useAppSelector((state) => state.user.loading);
  const allPackage: AllPackage = useAppSelector((state) => state.user.allPackage) as AllPackage;
  const packageHoudai: PackageHoudai[] = useAppSelector((state) => state.user.packageHoudai) as PackageHoudai[];
  const userInfo: UserInfo = useAppSelector((state) => state.user.userInfo) as UserInfo;

  const token = local.StorageGetSecure("access_token");
  const dataRegister = session.StorageGetSecure("data_register");
  const languageLocal = local.StorageGetSecure("language");
  const { t, i18n } = useTranslation(["service_info"]);

  useEffect(() => {
    if (token) {
      dispatch(getAllPackage());
      dispatch(getInfoAccount());
    }
    // if (userInfo?.phone) {
    //   dispatch(getQuotaInfo(userInfo?.phone));
    // }
  }, [dispatch, token]);
  useEffect(() => {
    i18n.changeLanguage(languageLocal || "ja");
  }, [i18n, languageLocal]);
  useEffect(() => {
    if (dataRegister) {
      form.setFieldsValue({
        phone: dataRegister?.phone || null,
        code_transfer: dataRegister?.code_transfer || null,
        expiration_date: dataRegister?.expiration_date ? dayjs(dataRegister?.expiration_date, "YYYY-MM-DD") : null,
      });
      setErrorState([
        !dataRegister?.service_type,
        !dataRegister?.sim_type,
        !dataRegister?.data_package,
        dataRegister?.sim_type === 1 && dataRegister?.call_package === undefined,
        dataRegister?.event === undefined,
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (dataRegister?.sim_type === 1) {
      dispatch(showPriceHoudai());
    }
  }, [dispatch, dataRegister?.sim_type, languageLocal]);

  // register service package
  const packageList: AllPackage[] = Object.values(allPackage || {});
  const registerSteps: StepProps[] = [
    {
      title: t("loai_dich_vu"),
      icon: <i className="fa-thin fa-mobile-signal"></i>,
    },
    {
      title: t("loai_sim"),
      icon: <i className="fa-thin fa-sim-cards"></i>,
    },
    {
      title: t("goi_cuoc_du_lieu"),
      icon: <i className="fa-thin fa-signal-bars"></i>,
    },
    ...(dataRegister?.sim_type === 1
      ? [{ title: t("goi_cuoc_goi_houdai"), icon: <i className="fa-thin fa-phone"></i> }]
      : []),
    {
      title: t("uu_dai"),
      icon: <i className="fa-thin fa-gift"></i>,
    },
    {
      title: t("common:xac_nhan"),
      icon: <i className="fa-thin fa-check"></i>,
    },
  ];
  const contentRegisterSteps = [
    {
      content: (
        <>
          <Radio.Group
            options={[
              { value: 1, label: t("dang_ki_moi") },
              { value: 2, label: t("chuyen_mang") },
            ]}
            value={dataRegister?.service_type !== undefined ? dataRegister?.service_type : null}
            onChange={(e) => {
              if (e.target.value) {
                session.StorageSetSecure("data_register", {
                  ...dataRegister,
                  service_type: e.target.value,
                  sim_type: undefined,
                  data_package: undefined,
                  call_package: undefined,
                  event: undefined,
                });
                setErrorState([false, true, true, true, true]);
              }
            }}
          />
          {dataRegister?.service_type === 1 && (
            <div className="service-package">
              <div className="service-package-item">
                <h1 className="service-package-label">{t("sim_nghe_goi")}</h1>
                <div className="service-package-content">
                  {packageList?.[0]?.data?.map((item: PackageInfo) => (
                    <Tooltip
                      title={
                        <p>
                          {Math.round(item?.data / 1024)} GB/{t("thang")}
                          <br />
                          {item?.description}
                        </p>
                      }
                    >
                      <span key={item?.id} className="service-package-content-item">
                        {item?.name}
                      </span>
                    </Tooltip>
                  ))}
                </div>
              </div>
              <div className="service-package-item">
                <h1 className="service-package-label">{t("sim_data")}</h1>
                <div className="service-package-content">
                  {packageList?.[1]?.data?.map((item: PackageInfo) => (
                    <Tooltip
                      title={
                        <p>
                          {Math.round(item?.data / 1024)} GB/{t("thang")}
                          <br />
                          {item?.description}
                        </p>
                      }
                    >
                      <span key={item?.id} className="service-package-content-item">
                        {item?.name}
                      </span>
                    </Tooltip>
                  ))}
                </div>
              </div>
            </div>
          )}
          {dataRegister?.service_type === 2 && (
            <>
              <Form
                form={form}
                initialValues={{
                  phone: dataRegister?.phone || null,
                  code_transfer: dataRegister?.code_transfer || null,
                  expiration_date: dataRegister?.expiration_date
                    ? dayjs(dataRegister?.expiration_date, "YYYY-MM-DD")
                    : null,
                }}
                wrapperCol={{ span: 8 }}
                labelCol={{ span: 4 }}
                labelAlign="left"
                className="network-transfer"
              >
                <Form.Item
                  label={t("so_dien_thoai")}
                  name="phone"
                  rules={[
                    { required: true, message: `${t("common:vui_long_nhap")} ${t("so_dien_thoai")}` },
                    { pattern: /^[0-9]*$/, message: t("yeu_cau_dinh_dang_so") },
                    { min: 10, max: 11, message: t("yeu_cau_so_dien_thoai") },
                  ]}
                  className="network-transfer-item"
                >
                  <Input placeholder={t("so_dien_thoai")} className="network-transfer-item-input" />
                </Form.Item>
                <Form.Item
                  label={t("ma_chuyen_mang")}
                  name="code_transfer"
                  rules={[
                    { required: true, message: `${t("common:vui_long_nhap")} ${t("ma_chuyen_mang")}` },
                    { pattern: /^[0-9]*$/, message: t("yeu_cau_dinh_dang_so") },
                  ]}
                  className="network-transfer-item"
                >
                  <Input
                    pattern="/^[0-9]*$/"
                    placeholder={t("ma_chuyen_mang")}
                    className="network-transfer-item-input"
                  />
                </Form.Item>
                <Form.Item
                  label={t("ngay_het_han")}
                  name="expiration_date"
                  rules={[{ required: true, message: `${t("common:vui_long_nhap")} ${t("ngay_het_han")}` }]}
                  className="network-transfer-item"
                >
                  <DatePicker
                    minDate={dayjs()}
                    placeholder={t("ngay_het_han")}
                    getPopupContainer={(trigger) => trigger.parentElement || document.body}
                    className="network-transfer-item-input date-picker"
                  />
                </Form.Item>
              </Form>
              <div className="service-package">
                <div className="service-package-item">
                  <h1 className="service-package-label">{t("sim_nghe_goi")}</h1>
                  <div className="service-package-content">
                    {packageList?.[0]?.data?.map((item: PackageInfo) => (
                      <Tooltip
                        title={
                          <p>
                            {Math.round(item?.data / 1024)} GB/{t("thang")}
                            <br />
                            {item?.description}
                          </p>
                        }
                      >
                        <span key={item?.id} className="service-package-content-item">
                          {item?.name}
                        </span>
                      </Tooltip>
                    ))}
                  </div>
                </div>
                <div className="service-package-item">
                  <h1 className="service-package-label">{t("sim_data")}</h1>
                  <div className="service-package-content">
                    {packageList?.[1]?.data?.map((item: PackageInfo) => (
                      <Tooltip
                        title={
                          <p>
                            {Math.round(item?.data / 1024)} GB/{t("thang")}
                            <br />
                            {item?.description}
                          </p>
                        }
                      >
                        <span key={item?.id} className="service-package-content-item">
                          {item?.name}
                        </span>
                      </Tooltip>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      ),
    },
    {
      content: (
        <Radio.Group
          options={packageList?.map((item: AllPackage) => ({
            value: item?.id,
            label: item?.id === 1 ? t("sim_nghe_goi") : t("sim_data"),
          }))}
          value={dataRegister?.sim_type !== undefined ? dataRegister?.sim_type : null}
          onChange={(e) => {
            if (e.target.value) {
              session.StorageSetSecure("data_register", {
                ...dataRegister,
                sim_type: e.target.value,
                data_package: undefined,
                call_package: undefined,
                event: undefined,
              });
              setErrorState([false, false, true, true, true]);
            }
          }}
        />
      ),
    },
    {
      content: (
        <Radio.Group
          options={packageList[dataRegister?.sim_type - 1]?.data?.map((item: PackageInfo) => ({
            value: item?.id,
            label: item?.name,
          }))}
          value={dataRegister?.data_package !== undefined ? dataRegister?.data_package : null}
          onChange={(e) => {
            if (e.target.value !== undefined) {
              session.StorageSetSecure("data_register", {
                ...dataRegister,
                data_package: e.target.value,
                call_package: undefined,
                event: undefined,
              });
              setErrorState([false, false, false, true, true]);
            }
            const newPackagePrice =
              packageList[dataRegister?.sim_type - 1]?.data?.find((item: PackageInfo) => item?.id === e.target.value)
                ?.price || 0;
            const newPackagePriceThisMonth = (newPackagePrice / numDateInMonth) * numDateCurrent;
            setPackagePrice(newPackagePrice);
            setPackagePriceThisMonth(newPackagePriceThisMonth);
            // packagePrice =
            //   packageList[dataRegister?.sim_type - 1]?.data?.find((item: PackageInfo) => item?.id === e.target.value)
            //     ?.price || 0;
            // console.log(formatPrice(packagePrice));
            // packagePriceThisMonth = (packagePrice / numDateInMonth) * numDateCurrent;
            // console.log(formatPrice(packagePriceThisMonth));
          }}
        />
      ),
    },
    ...(dataRegister?.sim_type === 1
      ? [
          {
            content: (
              <Radio.Group
                options={[
                  { value: 0, label: t("khong_dang_ky") },
                  { value: 1, label: t("5_phut") },
                  { value: 2, label: t("10_phut") },
                  { value: 3, label: t("15_phut") },
                ]}
                value={dataRegister?.call_package !== undefined ? dataRegister?.call_package : null}
                onChange={(e) => {
                  if (e.target.value !== undefined) {
                    session.StorageSetSecure("data_register", {
                      ...dataRegister,
                      call_package: e.target.value,
                      event: undefined,
                    });
                    setErrorState([false, false, false, false, true]);
                  }
                  const newHoudaiPrice = packageHoudai[e.target.value]?.price || 0;
                  const newHoudaiPriceThisMonth =
                    (packageHoudai[e.target.value || 0]?.price / numDateInMonth) * numDateCurrent;
                  setHoudaiPrice(newHoudaiPrice);
                  setHoudaiPriceThisMonth(newHoudaiPriceThisMonth);
                }}
              />
            ),
          },
        ]
      : []),
    {
      content: (
        <Radio.Group
          options={[
            { value: 0, label: t("khong_dang_ky") },
            { value: 1, label: t("goi_1_thang") },
            { value: 2, label: t("goi_3_thang") },
            { value: 3, label: t("goi_6_thang") },
          ]}
          value={dataRegister?.event !== undefined ? dataRegister?.event : null}
          onChange={(e) => {
            if (e.target.value !== undefined) {
              session.StorageSetSecure("data_register", { ...dataRegister, event: e.target.value });
              setErrorState([false, false, false, false, false]);
            }
            switch (e.target.value) {
              case 2:
                setPackagePriceThisMonth(0);
                break;
              case 3:
                setPackagePriceThisMonth(0);
                break;
              default:
                setPackagePriceThisMonth((packagePrice / numDateInMonth) * numDateCurrent);
                break;
            }
          }}
        />
      ),
    },
    {
      content: (
        <>
          <div className="register-item-content-register-confirm">
            <span className="register-confirm-label">{t("loai_dich_vu")}: </span>
            <span className="register-confirm-content">
              {dataRegister?.service_type === 1 ? t("dang_ki_moi") : t("chuyen_mang")}
            </span>
          </div>
          {dataRegister?.service_type === 2 && (
            <>
              <div className="register-item-content-register-confirm">
                <span className="register-confirm-label">{t("so_dien_thoai")}: </span>
                <span className="register-confirm-content">{dataRegister?.phone}</span>
              </div>
              <div className="register-item-content-register-confirm">
                <span className="register-confirm-label">{t("ma_chuyen_mang")}: </span>
                <span className="register-confirm-content">{dataRegister?.code_transfer}</span>
              </div>
              <div className="register-item-content-register-confirm">
                <span className="register-confirm-label">{t("ngay_het_han")}: </span>
                <span className="register-confirm-content">{dataRegister?.expiration_date}</span>
              </div>
            </>
          )}
          <div className="register-item-content-register-confirm">
            <span className="register-confirm-label">{t("loai_sim")}: </span>
            <span className="register-confirm-content">
              {packageList[dataRegister?.sim_type - 1]?.id === 1 ? t("sim_nghe_goi") : t("sim_data")}
            </span>
          </div>
          <div className="register-item-content-register-confirm">
            <span className="register-confirm-label">{t("goi_cuoc_du_lieu")}: </span>
            <span className="register-confirm-content">
              {
                packageList[dataRegister?.sim_type - 1]?.data?.find(
                  (item: PackageInfo) => item?.id === dataRegister?.data_package,
                )?.name
              }
            </span>
          </div>
          {dataRegister?.sim_type === 1 && (
            <div className="register-item-content-register-confirm">
              <span className="register-confirm-label">{t("goi_cuoc_goi_houdai")}: </span>
              <span className="register-confirm-content">
                {/* {dataRegister?.call_package === 0
                  ? t("khong_dang_ky")
                  : dataRegister?.call_package === 1
                  ? t("5_phut")
                  : dataRegister?.call_package === 2
                  ? t("10_phut")
                  : dataRegister?.call_package === 3
                  ? t("15_phut")
                  : t("khong_dang_ky")} */}
                {/* {packageHoudai[dataRegister?.call_package || 0]?.jp || t("khong_dang_ky")} */}
                {languageLocal === "ja"
                  ? packageHoudai && packageHoudai[dataRegister?.call_package || 0]?.jp
                  : languageLocal === "vi"
                  ? packageHoudai && packageHoudai[dataRegister?.call_package || 0]?.vn
                  : packageHoudai && packageHoudai[dataRegister?.call_package || 0]?.jp}
              </span>
            </div>
          )}
          <div className="register-item-content-register-confirm">
            <span className="register-confirm-label">{t("uu_dai")}: </span>
            <span className="register-confirm-content">
              {dataRegister?.event === 0
                ? t("khong_dang_ky")
                : dataRegister?.event === 1
                ? t("goi_1_thang")
                : dataRegister?.event === 2
                ? t("goi_3_thang")
                : dataRegister?.event === 3
                ? t("goi_6_thang")
                : t("khong_dang_ky")}
            </span>
          </div>
        </>
      ),
    },
  ];
  const itemsStep = registerSteps.map((item: StepProps, index: number) => ({
    key: index,
    ...item,
    onClick: () => {
      if (index < currentStep) {
        setCurrentStep(index);
      }
    },
  }));

  const handleStoreFormNetworkTransfer = (values: any) => {
    const data = {
      ...dataRegister,
      phone: values.phone,
      code_transfer: values.code_transfer,
      expiration_date: values.expiration_date.format("YYYY-MM-DD"),
    };
    session.StorageSetSecure("data_register", data);
  };
  const handleRegisterServicePackage = async () => {
    const data = {
      type: dataRegister?.service_type,
      phone: dataRegister?.phone,
      code_transfer: dataRegister?.code_transfer,
      expiration_date: dataRegister?.expiration_date,
      package_id: dataRegister?.data_package,
      sim_option: dataRegister?.sim_type === 2 ? 0 : dataRegister?.call_package,
      event: dataRegister?.event,
    };
    try {
      const response: any = await dispatch(registerServicePackage(data));
      if (response?.payload?.code === 200) {
        showMessage.success(response?.payload?.msg);
        setTimeout(() => {
          setCurrentStep(0);
          session.StorageRemoveSecure("data_register");
          setErrorState([true, true, true, true]);
          setPackagePrice(0);
          setPackagePriceThisMonth(0);
          setHoudaiPrice(0);
          setHoudaiPriceThisMonth(0);
        }, 1000);
      } else {
        showMessage.error(response?.payload?.msg);
      }
    } catch (error: any) {
      showMessage.error(error?.response?.data?.msg);
    }
  };

  // calculate total price
  const registerFee: number = 3300;
  const shippingFee: number = 0;
  const numDateInMonth: number = dayjs().daysInMonth();
  const numDateCurrent: number = numDateInMonth - dayjs().date();

  const topUpPackagePrice: number =
    dataRegister?.event === 1
      ? packagePrice
      : dataRegister?.event === 2
      ? packagePrice * 3
      : dataRegister?.event === 3
      ? packagePrice * 6
      : 0;
  const topUpHoudaiPrice: number =
    dataRegister?.event === 1
      ? houdaiPrice
      : dataRegister?.event === 2
      ? houdaiPrice * 3
      : dataRegister?.event === 3
      ? houdaiPrice * 6
      : 0;

  const packagePriceThisMonthDiscount: number = ((packagePrice - 400) / numDateInMonth) * numDateCurrent;
  const packageDiscountThisMonth: number =
    dataRegister?.event === 2
      ? 400 * 3
      : dataRegister?.event === 3
      ? 400 * 6
      : (packagePrice / numDateInMonth) * numDateCurrent - packagePriceThisMonthDiscount + 400;
  const discountRegisterFee: number = registerFee / 2;

  const totalPrice: number =
    dataRegister?.event === 1
      ? registerFee + shippingFee + packagePrice * 2 + houdaiPrice * 2 + houdaiPriceThisMonth + packagePriceThisMonth
      : dataRegister?.event === 2
      ? registerFee + shippingFee + packagePrice * 4 + houdaiPrice * 3 + houdaiPriceThisMonth
      : dataRegister?.event === 3
      ? registerFee + shippingFee + packagePrice * 7 + houdaiPrice * 6 + houdaiPriceThisMonth
      : registerFee + shippingFee + packagePrice + houdaiPrice + houdaiPriceThisMonth + packagePriceThisMonth;
  const totalPriceDiscount: number =
    dataRegister?.event === 2
      ? totalPrice - 400 * 3 - discountRegisterFee
      : dataRegister?.event === 3
      ? totalPrice - 400 * 6 - discountRegisterFee
      : totalPrice - packageDiscountThisMonth - discountRegisterFee;

  const instructionsCollapse: CollapseProps["items"] = [
    {
      key: 1,
      label: (
        <>
          <h2 className="instructions-title">{t("giay_to_xac_minh_danh_tinh")}</h2>
          <p className="instructions-subtitle">{t("mo_ta_giay_to_xac_minh_danh_tinh")}</p>
        </>
      ),
      children: (
        <div className="instructions-list">
          <div className="instructions-item">
            <h3 className="instructions-item-title">
              <i className="fa-light fa-paperclip"></i>
              <span className="instructions-item-title-text">{t("tieu_de_giay_to_xac_minh_danh_tinh")}</span>
            </h3>
            <ul className="instructions-item-content">
              <li>{t("giay_phep_lai_xe")}</li>
              <li>
                {t("ho_chieu")}
                <br />
                {t("luu_y_ho_chieu")}
              </li>
              <li>
                {t("the_can_cuoc")}
                <br />
                {t("luu_y_the_can_cuoc")}
              </li>
              <li>{t("the_cu_tru")}</li>
            </ul>
          </div>
          <div className="instructions-item">
            <h3 className="instructions-item-title">
              <i className="fa-light fa-circle-exclamation"></i>
              <span className="instructions-item-title-text">{t("tieu_de_luu_y")}</span>
            </h3>
            <ul className="instructions-item-content">
              <li>{t("thong_tin_ro_rang")}</li>
              <li>{t("tai_len_anh_mau")}</li>
              <li>{t("hinh_anh_bi_mo")}</li>
              <li>{t("tai_len_anh_ban_goc")}</li>
            </ul>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="wrapper register-service-package">
      {loading && overLoader()}
      <div className="main">
        <div className="main-content">
          <div className="register-service-package-list">
            {/* {userInfo?.verify?.[0]?.status === 1 && ( */}
            <div className="register-item">
              <h1 className="register-item-title">{t("dang_ki_goi_dich_vu")}</h1>
              <div className="register-item-content">
                <Steps
                  current={currentStep}
                  items={itemsStep}
                  size="small"
                  labelPlacement="vertical"
                  className="register-item-content-step"
                />
                <div className="register-item-content-register">{contentRegisterSteps[currentStep]?.content}</div>
                <div className="register-item-content-price">
                  <div className="price-item">
                    <span className="price-item-label">{t("chi_phi_dang_ki")}</span>
                    <span className="price-item-number">{formatPrice(registerFee)}</span>
                  </div>
                  <div className="price-item has-collapse">
                    {/* <span className="price-item-label">{t("chi_phi_hang_thang")}</span> */}
                    <Collapse
                      items={[
                        {
                          key: 1,
                          label: t("chi_phi_hang_thang"),
                          children: (
                            <div className="price-item-content">
                              <div className="price-item-content-item">
                                <span className="price-item-content-label">– {t("thang_hien_tai")}</span>
                                <span className="price-item-content-number">
                                  {formatPrice(packagePriceThisMonth + houdaiPriceThisMonth)}
                                </span>
                              </div>
                              <div className="price-item-content-item">
                                <span className="price-item-content-label">– {t("thang_tiep_theo")}</span>
                                <span className="price-item-content-number">{formatPrice(packagePrice)}</span>
                              </div>
                            </div>
                          ),
                        },
                      ]}
                      ghost
                      expandIconPosition="end"
                      expandIcon={({ isActive }) =>
                        isActive ? (
                          <span className="collapse-icon">
                            <i className="fa-light fa-chevron-up"></i>
                          </span>
                        ) : (
                          <>
                            <span className="collapse-total">
                              {formatPrice(packagePriceThisMonth + houdaiPriceThisMonth + packagePrice)}
                            </span>
                            <span className="collapse-icon">
                              <i className="fa-light fa-chevron-down"></i>
                            </span>
                          </>
                        )
                      }
                    />
                  </div>
                  {dataRegister?.event !== undefined && dataRegister?.event !== 0 && (
                    <div className="price-item has-collapse">
                      {/* <span className="price-item-label">{t("nap_cuoc")}</span> */}
                      <Collapse
                        items={[
                          {
                            key: 1,
                            label: t("nap_cuoc"),
                            children: (
                              <div className="price-item-content">
                                <div className="price-item-content-item">
                                  <span className="price-item-content-label">
                                    {dataRegister?.event === 1
                                      ? `– ${t("nap_cuoc_1_thang")} (${t("goi_cuoc_du_lieu")})`
                                      : dataRegister?.event === 2
                                      ? `– ${t("nap_cuoc_3_thang")} (${t("goi_cuoc_du_lieu")})`
                                      : dataRegister?.event === 3
                                      ? `– ${t("nap_cuoc_6_thang")} (${t("goi_cuoc_du_lieu")})`
                                      : `– ${t("nap_cuoc_1_thang")} (${t("goi_cuoc_du_lieu")}`}
                                  </span>
                                  <span className="price-item-content-number">{formatPrice(topUpPackagePrice)}</span>
                                </div>
                                {dataRegister?.sim_type !== undefined && dataRegister?.sim_type === 1 && (
                                  <div className="price-item-content-item">
                                    <span className="price-item-content-label">
                                      {dataRegister?.event === 1
                                        ? `– ${t("nap_cuoc_1_thang")} (${t("goi_cuoc_goi_houdai")})`
                                        : dataRegister?.event === 2
                                        ? `– ${t("nap_cuoc_3_thang")} (${t("goi_cuoc_goi_houdai")})`
                                        : dataRegister?.event === 3
                                        ? `– ${t("nap_cuoc_6_thang")} (${t("goi_cuoc_goi_houdai")})`
                                        : `– ${t("nap_cuoc_1_thang")} (${t("goi_cuoc_goi_houdai")}`}
                                    </span>
                                    <span className="price-item-content-number">{formatPrice(topUpHoudaiPrice)}</span>
                                  </div>
                                )}
                              </div>
                            ),
                          },
                        ]}
                        ghost
                        expandIconPosition="end"
                        expandIcon={({ isActive }) =>
                          isActive ? (
                            <span className="collapse-icon">
                              <i className="fa-light fa-chevron-up"></i>
                            </span>
                          ) : (
                            <>
                              <span className="collapse-total">
                                {formatPrice(topUpPackagePrice + topUpHoudaiPrice)}
                              </span>
                              <span className="collapse-icon">
                                <i className="fa-light fa-chevron-down"></i>
                              </span>
                            </>
                          )
                        }
                      />
                    </div>
                  )}
                  {/* <div className="price-item">
                          <span className="price-item-label italic">{t("tong_chi_phi")}</span>
                          <span className="price-item-number">{formatPrice(totalPrice)}</span>
                        </div> */}
                  <div className="price-item">
                    <span className="price-item-label">{t("phi_giao_hang")}</span>
                    <span className="price-item-number">{formatPrice(shippingFee)}</span>
                  </div>
                  <div className="price-item has-collapse">
                    {/* <span className="price-item-label">{t("giam_gia")}</span> */}
                    <Collapse
                      items={[
                        {
                          key: 1,
                          label: t("giam_gia"),
                          children: (
                            <div className="price-item-content">
                              <div className="price-item-content-item">
                                <span className="price-item-content-label">– {t("giam_gia_cuoc_phi")}</span>
                                <span className="price-item-content-number">
                                  {formatPrice(-packageDiscountThisMonth)}
                                </span>
                              </div>
                              <div className="price-item-content-item">
                                <span className="price-item-content-label">
                                  – {`${t("giam_gia_phi_dang_ki")} (50%)`}
                                </span>
                                <span className="price-item-content-number">{formatPrice(-discountRegisterFee)}</span>
                              </div>
                            </div>
                          ),
                        },
                      ]}
                      ghost
                      expandIconPosition="end"
                      expandIcon={({ isActive }) =>
                        isActive ? (
                          <span className="collapse-icon">
                            <i className="fa-light fa-chevron-up"></i>
                          </span>
                        ) : (
                          <>
                            <span className="collapse-total">
                              {formatPrice(-packageDiscountThisMonth - discountRegisterFee)}
                            </span>
                            <span className="collapse-icon">
                              <i className="fa-light fa-chevron-down"></i>
                            </span>
                          </>
                        )
                      }
                    />
                  </div>
                  <div className="price-item">
                    <span className="price-item-label bold">{t("tong_tien")}</span>
                    <span className="price-item-number bold">{formatPrice(totalPriceDiscount)}</span>
                  </div>
                </div>
                <div className="register-item-content-button">
                  {currentStep < registerSteps.length - 1 && (
                    <Button
                      type="primary"
                      onClick={() => {
                        if (currentStep === 0 && dataRegister?.service_type === 2) {
                          form
                            .validateFields()
                            .then((values) => {
                              handleStoreFormNetworkTransfer(values);
                              setCurrentStep(currentStep + 1);
                            })
                            .catch(() => {});
                        } else {
                          setCurrentStep(currentStep + 1);
                        }
                      }}
                      disabled={errorState[currentStep]}
                    >
                      {t("common:tiep_theo")}
                    </Button>
                  )}
                  {currentStep === registerSteps.length - 1 && (
                    <Button
                      type="primary"
                      onClick={
                        userInfo?.verify?.[0]?.status === 1 ? handleRegisterServicePackage : () => setShowModal(true)
                      }
                    >
                      {t("common:xac_nhan")}
                    </Button>
                  )}
                  {currentStep > 0 && (
                    <Button onClick={() => setCurrentStep(currentStep - 1)}>{t("common:quay_lai")}</Button>
                  )}
                </div>
              </div>
            </div>
            {/* )} */}
            {(userInfo?.verify?.length === 0 || userInfo?.verify?.[0]?.status !== 1) && (
              //   <div className="register-item">
              <Modal
                //   className="register-item"
                open={showModal}
                centered
                footer={null}
                onCancel={() => setShowModal(false)}
                getContainer={document.querySelector(".register-item") as HTMLElement}
                title={userInfo?.verify?.[0]?.status === 0 ? t("cho_xac_thuc") : t("chua_xac_thuc")}
              >
                <h1 className="register-item-title">{t("nhung_thong_tin_can_cung_cap_khi_dang_ki_goi_dich_vu")}</h1>
                <Collapse
                  items={instructionsCollapse}
                  // defaultActiveKey={1}
                  ghost
                  collapsible="icon"
                  expandIcon={() => <span>{t("xem_chi_tiet")}</span>}
                  expandIconPosition="end"
                  className="register-item-instructions"
                />
                <div className="register-item-button">
                  <Link to="/customer_info" state={{ isVerified: false }} className="register-item-button-link">
                    <Button type="primary">{t("chuyen_den_trang_xac_thuc_thong_tin")}</Button>
                  </Link>
                </div>
              </Modal>
              //   </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
