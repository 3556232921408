import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { getInfoAccount, getUsageHistory } from "../../redux/slices/userSlice";
import { UsageHistoryInfo, UserInfo } from "../../types/common";
import overLoader from "../../components/overLoader";
import { DatePicker, Select } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

export const UsageHistory = () => {
  const [currentMonth, setCurrentMonth] = useState(dayjs().format("YYYYMM"));
  const [currentSim, setCurrentSim] = useState<string>("");

  // redux
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.user.loading);
  const userInfo: UserInfo = useAppSelector((state) => state.user.userInfo) as UserInfo;
  // const contractInfo: ContractInfo[] = useAppSelector((state) => state.user.contractInfo || []) as ContractInfo[];
  const usageHistory: UsageHistoryInfo = useAppSelector((state) => state.user.usageHistory) as UsageHistoryInfo;

  // const listSim = contractInfo?.filter((sim) => sim.status === 1);
  const optionsSim = userInfo?.list_sim?.map((item, index) => {
    return { label: item, value: index };
  });

  const { t } = useTranslation(["usage_history", "customer_info", "common"]);

  useEffect(() => {
    dispatch(getInfoAccount());
    // dispatch(getContractInfo());
  }, [dispatch]);
  useEffect(() => {
    if (userInfo?.list_sim) {
      dispatch(getUsageHistory({ phone: userInfo?.list_sim[0], month: dayjs().format("YYYYMM") }));
    }
  }, [dispatch, userInfo?.list_sim]);

  const handleSimChange = (value: number) => {
    dispatch(getUsageHistory({ phone: userInfo?.list_sim[value], month: currentMonth }));
    setCurrentSim(userInfo?.list_sim[value]);
  };
  const handleMonthChange = (date: dayjs.Dayjs) => {
    dispatch(getUsageHistory({ phone: currentSim ? currentSim : userInfo?.list_sim[0], month: date.format("YYYYMM") }));
    setCurrentMonth(date.format("YYYYMM"));
  };

  return (
    <div className="wrapper usage-history">
      {loading && overLoader()}
      <div className="main">
        <div className="main-content">
          {/* <div className="usage-history-list"> */}
          <div className="usage-history-item usage-history-title">
            <h1 className="usage-history-title-name">Utility House 株式会社</h1>
            <span className="usage-history-title-description">{t("lich_su_su_dung")}</span>
          </div>
          <div className="usage-history-item customer-info">
            <h1 className="customer-info-title">{t("customer_info:thong_tin_ca_nhan")}</h1>
            <table className="customer-info-table">
              <tbody>
                <tr className="customer-info-table-row">
                  <td className="customer-info-table-cell">{t("customer_info:ho_ten")}</td>
                  <td className="customer-info-table-cell">{userInfo?.name}</td>
                </tr>
                <tr className="customer-info-table-row">
                  <td className="customer-info-table-cell">{t("customer_info:so_dien_thoai")}</td>
                  {/* <td className="customer-info-table-cell">08083554855</td> */}
                  <td className="customer-info-table-cell">
                    <Select options={optionsSim} defaultValue={0} onChange={handleSimChange} className="select-sim" />
                  </td>
                </tr>
                <tr className="customer-info-table-row">
                  <td className="customer-info-table-cell">{t("ngay_thanh_toan")}</td>
                  {/* <td className="customer-info-table-cell">2024/05</td> */}
                  <td className="customer-info-table-cell">
                    <DatePicker
                      picker="month"
                      defaultValue={currentMonth ? dayjs(currentMonth) : dayjs()}
                      onChange={handleMonthChange}
                      format="YYYY/MM"
                      className="select-month"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="usage-history-item payment-amount">
            {/* <Select options={optionsSim} defaultValue={0} onChange={handleSimChange} className="payment-amount-sim" /> */}
            <div className="payment-amount-list">
              <div className="payment-amount-title">
                <h1 className="payment-amount-title-phone">{currentSim ? currentSim : userInfo?.list_sim[0]}</h1>
                <span className="payment-amount-title-price">
                  {`${usageHistory?.totalPrice} 円 ${t("bao_gom_thue")}`}
                </span>
              </div>
              <div className="payment-amount-item">
                <span className="payment-amount-item-text">{usageHistory?.packageName}</span>
                <span className="payment-amount-item-price">
                  {`${usageHistory?.packagePrice} 円 (${t("bao_gom_thue")})`}
                </span>
              </div>
              <div className="payment-amount-item">
                <span className="payment-amount-item-text">{t("cuoc_goi_phi_sms")}</span>
                <span className="payment-amount-item-price">{`${usageHistory?.total} 円 (${t("bao_gom_thue")})`}</span>
              </div>
              <div className="payment-amount-item">
                <span className="payment-amount-item-text">{t("phi_dich_vu")}</span>
                <span className="payment-amount-item-price">{`${usageHistory?.fee} 円 (${t("bao_gom_thue")})`}</span>
              </div>
            </div>
          </div>
          <div className="usage-history-item usage-statistic">
            <table className="usage-statistic-table">
              <thead>
                <tr className="usage-statistic-table-row">
                  <th className="usage-statistic-table-header">{t("ngay_lien_lac")}</th>
                  <th className="usage-statistic-table-header">{t("so_dien_thoai_lien_lac")}</th>
                  <th className="usage-statistic-table-header">{t("loai_lien_lac")}</th>
                  <th className="usage-statistic-table-header">{t("thoi_luong_cuoc_goi")}</th>
                  <th className="usage-statistic-table-header">{t("cuoc_lien_lac")}</th>
                  <th className="usage-statistic-table-header">{t("phi_su_dung")}</th>
                </tr>
              </thead>
              <tbody>
                {usageHistory?.listTalk?.length > 0 ? (
                  usageHistory?.listTalk?.map((item, index) => {
                    return (
                      <tr className="usage-statistic-table-row" key={index}>
                        <td className="usage-statistic-table-cell">{item.date}</td>
                        <td className="usage-statistic-table-cell">{item.phone_to}</td>
                        <td className="usage-statistic-table-cell">{item.destination_type_text}</td>
                        <td className="usage-statistic-table-cell">{item.call_time}</td>
                        <td className="usage-statistic-table-cell">{item.call_charges}</td>
                        <td className="usage-statistic-table-cell">{item.call_fee}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="usage-statistic-table-row">
                    <td className="usage-statistic-table-cell" colSpan={6}>
                      <span className="usage-statistic-table-cell-icon">
                        <i className="fa-thin fa-ban"></i>
                      </span>
                      <span>{t("common:khong_co_du_lieu")}</span>
                    </td>
                  </tr>
                )}
                {/* <tr className="usage-statistic-table-row">
                  <td className="usage-statistic-table-cell">20240504</td>
                  <td className="usage-statistic-table-cell">0570081818</td>
                  <td className="usage-statistic-table-cell">他　社</td>
                  <td className="usage-statistic-table-cell">9s</td>
                  <td className="usage-statistic-table-cell">540</td>
                  <td className="usage-statistic-table-cell">¥1,170</td>
                </tr>
                {/* 3 row to calculate price */}
                <tr className="usage-statistic-table-row">
                  <td className="usage-statistic-table-cell" colSpan={4}></td>
                  <td className="usage-statistic-table-cell title-money">{t("tong_phu")}</td>
                  <td className="usage-statistic-table-cell value-money">¥{usageHistory?.subTotal}</td>
                </tr>
                <tr className="usage-statistic-table-row">
                  <td className="usage-statistic-table-cell" colSpan={4}></td>
                  <td className="usage-statistic-table-cell title-money">{t("thue")}</td>
                  <td className="usage-statistic-table-cell value-money">¥{usageHistory?.consumptionTax}</td>
                </tr>
                <tr className="usage-statistic-table-row">
                  <td className="usage-statistic-table-cell" colSpan={4}></td>
                  <td className="usage-statistic-table-cell title-money">{t("tong")}</td>
                  <td className="usage-statistic-table-cell value-money">¥{usageHistory?.total}</td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};
